import { Grid } from '@mui/material';
import { NextPage } from 'next';
import useTranslation from 'next-translate/useTranslation';
import BaseError from '@components/BaseError';
import HeaderBar from '@components/HeaderBar';
import { MARGIN_BEFORE_FOOTER } from '@styles/consts';

// Known issue with warning about not having the initial props here :
// https://github.com/vercel/next.js/discussions/17004

const Page404: NextPage = () => {
    const { t } = useTranslation('common');

    return (
        <Grid alignItems="center" direction="column" sx={{ mb: MARGIN_BEFORE_FOOTER }} container>
            <HeaderBar title={t('error.page404.mainTitle')} />
            <Grid sx={{ mt: 4, width: '95vw' }} item>
                <BaseError
                    linkHref="/"
                    linkLabel={t('error.page404.link')}
                    logo="/assets/error.svg"
                    subtitle={t('error.page404.subtitle')}
                    title={t('error.page404.title')}
                />
            </Grid>
        </Grid>
    );
};

export default Page404;
